import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/CheckCircleSharp';
import FileCopyIcon from '@material-ui/icons/FolderOpenOutlined';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { relative } from 'path';

const styles = theme => ({    
    card: {
        display: 'flex'                
    },
    cardDetails: {
        display: 'flex',
        'flex-grow': 1,
        'flex-direction': 'column',
        height: 335,
        position: relative
    },
    cardIcon: {
        width: 160,
        backgroundColor: theme.palette.grey[800],
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'flex-direction': 'column'        
    },
    fileCopyIcon: {
        color: 'white',
        fontSize: 96
    },
    cloudUploadText: {
        color: 'white'      
    },     
    leftIcon: {
        marginRight: theme.spacing.unit
    },    
    dbfile: {                
        // 'border-bottom': '0.5px solid',
        display: 'flex',
        justify: 'flex-end',
        alignItems: 'center'
    },
    uploadButton: {
        width: 60
    },
    checkIcon: {
        color: 'green'
    }    
});

const ButtonLable = ({songsDb, wordsDb, readyToConvert, processing}) => {
    const dbsUploaded = songsDb && wordsDb;
    let msg = '';    

    if(dbsUploaded){
        msg = readyToConvert ? 'Click to download converted songs...' : 'Select settings on the right...';
    }
    if(processing){
        msg = "Processing, please wait...";
    }    
    
    return (                   
        <Typography variant="headline" gutterBottom style={{color: 'red'}}>
            {msg}
        </Typography>        
    );
}

class FileUploadCard extends Component {                   
    state = {
        platform: '',
        format: '',
        resolution: ''
    }         

    render() {
        const { classes, songsDb, wordsDb, readyToConvert, onProcessSongs, processing, onReset } = this.props;
                
        return (
            <Card className={classes.card}>
                <CardContent id="drop" className={[classes.cardDetails, classes.drop].join(' ')}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={16}>                        
                        <Grid item xs={12}>
                            <Typography component="h2" variant="headline" color="textSecondary" align="center">
                            Drag and Drop Songs.db and SongWords.db files
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            { songsDb && 
                                <Typography component="h2" variant="display1" color="textPrimary" className={classes.dbfile}>
                                <CheckIcon className={classes.checkIcon} /> Songs.db
                                </Typography>
                            }                            
                        </Grid>
                        <Grid item xs={12}>
                            { wordsDb &&
                                <Typography component="h2" variant="display1" color="textPrimary" className={classes.dbfile}>
                                <CheckIcon className={classes.checkIcon} /> SongWords.db
                                </Typography>   
                            }                            
                        </Grid>
                        <Grid item xs={12}>                            
                            <ButtonLable {...this.props} />
                            {(songsDb && wordsDb) &&
                                <div>
                                    <Button variant="contained" color="primary" component="label" onClick={onProcessSongs} disabled={!readyToConvert || processing}>
                                        <CloudDownloadIcon className={classes.leftIcon} /> {!processing ? 'Download' : 'Processing...' }
                                    </Button>
                                    &nbsp;
                                    <Button variant="contained" color="primary" component="label" onClick={onReset} disabled={!readyToConvert || processing}>
                                    Reset
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent className={classes.cardIcon}>
                    <FileCopyIcon className={classes.fileCopyIcon} />
                </CardContent>
            </Card>
        );
    }
}

export default withStyles(styles)(FileUploadCard);