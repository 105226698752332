import sanitze from 'sanitize-filename';
import parseRTF from 'rtf-parser';

class FileParser {
    readDatabaseFiles = (songsDb, wordsDb) => {
        const SQL = window.SQL;        
        
        let songsDbPromise = new Promise((resolve, reject) => {
            try {
                let songDbReader = new FileReader();

                songDbReader.onload = () => {
                    const Uints = new Uint8Array(songDbReader.result);
                    const db = new SQL.Database(Uints);

                    let result = db.exec("SELECT rowid, song_uid, title FROM song")[0];
                    let songMap = result.values.reduce((map, [songId, uid, title]) => {
                        let songTitle = title.trim();
                        let fileName = sanitze(songTitle);
                                               
                        // check for duplicate song titles then sufix a number if any exists
                        let songIndex = result.values.filter(row => row[2].toLowerCase() === songTitle.toLowerCase()).findIndex(row => row[0] === songId);
                        if(songIndex > 0){
                            fileName += '_' + (songIndex + 1);
                        }

                        map[songId] = {                            
                            title: songTitle,
                            words: '',
                            fileName: fileName                            
                        };

                        return map;
                    }, {});

                    resolve(songMap);
                };

                songDbReader.readAsArrayBuffer(songsDb);
            }
            catch (e) {
                reject(new Error(e));
            }
        });

        let wordsDbPromise = new Promise((resolve, reject) => {
            try {
                let wordsDbReader = new FileReader();

                wordsDbReader.onload = () => {
                    const Uints = new Uint8Array(wordsDbReader.result);
                    const db = new SQL.Database(Uints);

                    let result = db.exec("SELECT song_id, words FROM word")[0];
                    
                    result.values.forEach((item, i) => {                        
                        this.convertToPlainText(item[1]).then(cleanedWords => {                            
                            item[1] = cleanedWords;
                            if(i === result.values.length - 1){
                                resolve(result.values);
                            }
                        });                        
                    });                    
                };
                
                wordsDbReader.readAsArrayBuffer(wordsDb);
            }
            catch (e) {
                reject(new Error(e));
            }
        });

        return Promise.all([songsDbPromise, wordsDbPromise]).then(([songsResult, wordsResult]) => {            
            return wordsResult.reduce((r, [songId, words]) => {                    
                if (songsResult[songId]) {                    
                    songsResult[songId].words = words;
                    r.push(songsResult[songId]);                                               
                }

                return r;
            }, []);
        });
    }    

    /*
    Strips RTF encoding from the EW lyrics string. Returns a promise.
    */
   convertToPlainText = (rtf) => {        
        return new Promise((resolve) => {
            parseRTF.string(rtf, (err, doc) => {
                const content = doc.content;
                
                let stripedRtf = content.reduce((r, item, i) => {
                    if(i > 0){
                        r += '\r\n';
                    }                
    
                    if(item.content && item.content.length){
                        r += item.content[0].value.trim();
                    } else if (item.value){
                        r += item.value.trim();
                    }
    
                    return r;
                }, '');
    
                resolve(stripedRtf);
            });
        });
    }
}

export default FileParser;