import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// App helpers
import _ from 'underscore';
import FileParser from './util/FileParser';
import ProPresenter from './util/ProPresenter';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// App components
import FileUploadCard from './components/FileUploadCard';
import SettingsCard from './components/SettingsCard';
import './App.css';
import { debug } from 'util';

const styles = theme => ({
    layout: {
        width: 'auto',
        height: '100%',
        marginLeft: theme.spacing.unit * 8,
        marginRight: theme.spacing.unit * 8,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto'
        }        
    },
    banner: {
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing.unit * 4,
        height: 225
    },
    donnationPanel: {
        backgroundColor: '#FF5621',
        color: theme.palette.common.white,
        marginTop: theme.spacing.unit * 4,
        height: 200
    },
    donnationPanelContent: {
        padding: `${theme.spacing.unit * 2}px`        
    },    
    instructionsPanelContent: {
        padding: `${theme.spacing.unit * 2}px`        
    },
    bannerPostContent: {
        padding: `${theme.spacing.unit * 6}px`,
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
        }
    },
    modal: {
        border: 'dashed grey 2px',
        backgroundColor: 'rgba(255,255,255,.8)',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0, 
        right: 0,
        zIndex: 9999,
        width: '100%',
        height: '100%'
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        right: 0,
        left: 0,
        textAlign: 'center',
        color: 'grey',
        fontSize: 36
    }    
});

class App extends Component {             
    static initialState = () => ({
        songsDb: null,
        wordsDb: null,
        errors: [],
        songsCount: 0,
        readyToConvert: false,
        options: {},
        optionsReady: false,
        processing: false,
        downloadDone: false,
        dragging: false        
    });
    
    state = App.initialState();

    onFileDrop = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        let files = [];
        if (ev.dataTransfer.items) {
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    files.push(ev.dataTransfer.items[i].getAsFile());
                }
            }
        } else {
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                files.push(ev.dataTransfer.files[i]);
            }
        }
                
        let songsDb = _.find(files, (item) => item.name === 'Songs.db') || null;
        let wordsDb = _.find(files, (item) => item.name === 'SongWords.db') || null;
        
        let newState = {};
        if (songsDb && wordsDb) {
            newState = { songsDb: songsDb, wordsDb: wordsDb };
        } else if (songsDb) {
            newState = { songsDb: songsDb };
        } else if (wordsDb) {
            newState = { wordsDb: wordsDb };
        }

        this.setState({ ...newState, dragging: false });
    }  
    
    onProcessSongs = () => {         
        const fileParser = new FileParser();
        const options = {...this.state.options};
        const extension = options.format;
        const gtag = window.gtag;

        this.setState({ processing: true }, () => {                                              
            setTimeout(() => {
                gtag('event', 'song_convertion', {
                    'event_category' : 'db_convertion',
                    'event_label': 'Format Format',
                    'value': options.format
                });
                gtag('event', 'song_convertion', {
                    'event_category' : 'db_convertion',
                    'event_label': 'Format OS',
                    'value': options.platform === 1 ? 'Mac' : 'PC'
                });

                fileParser.readDatabaseFiles(this.state.songsDb, this.state.wordsDb).then(processedSongs => {                                   
                    if(extension === 'pro6'){
                        const proPresenter = new ProPresenter();
                        processedSongs = proPresenter.generateProPresFileContents(processedSongs, options);
                    }
        
                    const zip = new JSZip();            
                    processedSongs.forEach(song => zip.file(song.fileName + '.' + extension, song.words));
                    zip.generateAsync({type:"blob"})
                        .then(content => saveAs(content, 'EW Export.zip'))
                        .then(() => {
                            this.setState({
                                songsCount: processedSongs.length,
                                processing: false,
                                downloadDone: true
                            });
                    });
                                 
                    gtag('event', 'song_convertion', {
                        'event_category' : 'db_convertion',
                        'event_label': 'Song Count',
                        'value': processedSongs.length
                    });
                }).catch(reason => console.log(reason));
            }, 200);            
        });        
    }   

    onValidOptions = (valid, options) => {        
        this.setState({
            optionsReady: valid,
            options: options
        });
    }

    onReset = () => {        
        this.setState({...App.initialState(), optionsReady: this.state.optionsReady, options: this.state.options});        
    }

    componentDidUpdate() {
        const { songsDb, wordsDb, optionsReady } = this.state;
        
        let readyToConvert = (!!songsDb && !!wordsDb && optionsReady) ? true : false;        
        if(this.state.readyToConvert !== readyToConvert){
            this.setState({readyToConvert: readyToConvert});
        }
    }    

    onDragOver = (ev) => {
        ev.preventDefault();  
        ev.stopPropagation();
    }

    onDragEnter = (ev) => {        
        ev.preventDefault();  
        ev.stopPropagation();        
        this.setState({ dragging: true });             
    }

    onDragLeave = (ev) => {
        ev.preventDefault();               
        ev.stopPropagation(); 
        this.setState({ dragging: false });                     
    }            

    render() {
        const { classes } = this.props;
        const { songsDb, wordsDb, readyToConvert, songsCount, processing, downloadDone, dragging } = this.state;
        
        return (
            <Fragment>
                <CssBaseline />                                
                <div style={{display: dragging ? 'block' : 'none'}}
                    className={[classes.modal, dragging ? 'nopointerevents' : ''].join(' ')}
                    onDragOver={this.onDragOver}
                    onDrop={this.onFileDrop}
                    onDragLeave={this.onDragLeave}>
                    <div className={classes.modalContent}>
                        <Typography variant="h4" align="center">
                        Drop Song File(s) Here
                        </Typography>
                    </div>
                </div>                
                <div className={classes.layout}
                    onDragOver={this.onDragOver}                        
                    onDragEnter={this.onDragEnter}>
                    <Paper className={classes.banner}>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <div className={classes.bannerPostContent}>
                                    <Typography component="h1" variant="display2" color="inherit" gutterBottom>
                                    EW Converter
                                    </Typography>                                    
                                    <Typography variant="headline" color="inherit" paragraph>
                                    Convert your EasyWorship song database to plain text or ProPresenter 6 formats!
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container spacing={40}>
                        <Grid item xs={12} md={12} className={classes.instructionsPanelContent}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="headline" color="primary">
                                    Click for instructions on how to find Songs.db and SongWords.db files
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>  
                                    <ol>
                                        <li>Open EasyWorship</li>
                                        <li>Click the Profiles menu.</li>
                                        <li>Click Profiles Manager.</li>
                                        <li>Select your profile, then click on the blue link next to the words 'Instance Location' in the middle of that window.</li>
                                        <li>A new file explorer window will open.</li>
                                        <li>Click into the folder with the version name (ex. v6.1), then navigate into the Databases -> Data folder.</li>
                                        <li>Drag the Songs.db and SongWords.db files into your browser window.</li>
                                    </ol>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={40}> 
                        <Grid item xs={6} md={6}>                            
                            <FileUploadCard
                                songsDb={songsDb}
                                wordsDb={wordsDb}
                                readyToConvert={readyToConvert}
                                onFileChange={this.onFileChange}                                
                                onProcessSongs={this.onProcessSongs}
                                processing={processing}
                                downloadDone={downloadDone}
                                onReset={this.onReset}                                                            
                            /> 
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <SettingsCard onValidOptions={this.onValidOptions} />
                        </Grid>                   
                    </Grid>
                    { downloadDone &&
                    <Paper className={classes.donnationPanel}>
                        <Grid container>                        
                            <Grid item xs={6} md={12} className={classes.donnationPanelContent}>
                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_s-xclick" />
                                <input type="hidden" name="hosted_button_id" value="YD37JQVE9WLUA" />
                                <input type="image" src={"https://ewconverter.com/PayPal-donate-button.png"} width="133" height="72" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                </form>
                                <Typography variant="headline" color="inherit">
                                Congrats! A total of <b>{songsCount}</b> songs were converted!<br/>
                                I hope this tool saved you allot of time. Please consider a small donation if you've found it to be useful. It would be greatly appreciated.                                
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                    }                    
                </div>
            </Fragment>        
        );    
    }   
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);