import React, {Component} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import Grid from '@material-ui/core/Grid';

const styles = theme => ({    
    card: {
        display: 'flex'        
    },
    cardDetails: {
        flex: 1,
        height: 300
    },
    cardIcon: {
        width: 160,
        backgroundColor: theme.palette.grey[800],
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    },
    settingsCog: {
        color: 'white',
        fontSize: 96
    },
    formControl: {
        minWidth: 120
    }    
});

class SettingsCard extends Component {
    static initialState = () => ({
        platform: '',
        format: '',
        resolution: ''
    });
    
    state = SettingsCard.initialState();

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => this.checkValidity(this.state));
    }

    checkValidity = (state) => {
        let options = {...state};
        let valid = true;

        if(options.format === 'txt'){
            options.resolution = '';
            options.platform = '';
        }

        if(options.format === 'pro6' && (options.resolution === '' || options.platform === '')){
            valid = false;                     
        }
        
        this.props.onValidOptions(valid, valid ? options : {});        
    }       

    render() {
        const { classes } = this.props;

        return (
            <Card className={classes.card}>
                <CardContent className={classes.cardDetails}>                    
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={16}>
                        <Grid item>
                            <Typography component="h2" variant="headline" color="textSecondary">
                            Settings
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="format">Format</InputLabel>
                                <Select value={this.state.format} onChange={this.handleChange} name="format">                            
                                    <MenuItem value="txt">Plain text</MenuItem>
                                    <MenuItem value="pro6">ProPresenter 6</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.state.format === 'txt' || this.state.format === ''}>
                                <InputLabel htmlFor="resolution">Resolution</InputLabel>
                                <Select value={this.state.resolution} onChange={this.handleChange} name="resolution">
                                    <MenuItem value={1080}>1080p</MenuItem>
                                    <MenuItem value={720}>720p</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <FormControl className={classes.formControl} disabled={this.state.format === 'txt' || this.state.format === ''}>
                                <InputLabel htmlFor="platform">Platform</InputLabel>
                                <Select value={this.state.platform} onChange={this.handleChange} name="platform">                            
                                    <MenuItem value={1}>Mac</MenuItem>
                                    <MenuItem value={2}>PC</MenuItem>                            
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardContent className={classes.cardIcon}>
                    <SettingsIcon className={classes.settingsCog} />
                </CardContent>                
            </Card>
        );
    }
}

export default withStyles(styles)(SettingsCard);