import XmlWriter from './simple-xml-writer'

class ProPresenter { 
    generateProPresFileContents = (songs, options) => {        
        let labelRegex = new RegExp('^(Tag|Intro|Verse|Chorus|Bridge|End|Ending|Pre-Chorus)$', 'i');
        let labelRegexWithNum = new RegExp('^(Tag|Intro|Verse|Chorus|Bridge|End|Ending|Pre-Chorus)\\s?(\\d+)$', 'i');            
        let width = options.resolution === '720' ? 1280 : 1920;
        let height = options.resolution === '720' ? 720 : 1080;
        let os = options.platform;

        return songs.map(song => {            
            let slides = song.words.split('\r\n\r\n').map(line => line.split('\n'));
            let groups = [{ label: '', slides: [] }];
            
            slides.forEach((lines, i) => {                
                let firstLine = lines[0].trim();
                let label = firstLine.match(labelRegex) || firstLine.match(labelRegexWithNum);
                
                if(label && label.length){                                        
                    if(i === 0){
                        groups[0].label = firstLine;
                        groups[0].slides.push(lines.splice(1));
                    } else {
                        groups.push({
                            label: firstLine,                        
                            slides: [lines.splice(1)]
                        });
                    }
                } else {                    
                    groups[groups.length - 1].slides.push(lines);
                }                
            });            

            return { title: song.title, fileName: song.fileName, words: this.makeSongXml(height, width, os, groups) };
        });
    }    

    // Returns an XML string for a group
    makeSongXml = (height, width, os, groups) => {
        return XmlWriter(el => {
            el('RVPresentationDocument', (el, at) => {
                at('height', height);
                at('width', width);
                at('docType', 0);
                at('usedCount', 0);
                at('versionNumber', '600');
                at('backgroundColor', '0 0 0 1');
                at('drawingBackgroundColor', false);
                at('CCLIDisplay', false);
                at('lastDateUsed', '');
                at('selectedArrangementID', '');
                at('category', 'Presentation');
                at('resourcesDirectory', '');
                at('notes', '');
                at('CCLIAuthor', '');
                at('CCLIArtistCredits', '');
                at('CCLISongTitle', '');
                at('CCLIPublisher', '');
                at('CCLICopyrightYear', '');
                at('CCLISongNumber', '');
                at('chordChartPath', '');
                at('os', os);
                at('buildNumber', '6016');

                el('RVTimeline', (el, at) => {
                    at('timeOffset', 0);
                    at('duration', 0);
                    at('selectedMediaTrackIndex', '-1');
                    at('loop', false);
                    at('rvXMLIvarName', 'timeline');

                    el('array', (el, at) => at('rvXMLIvarName', 'timeCues'));
                    el('array', (el, at) => at('rvXMLIvarName', 'mediaTracks'));
                });
                el('array', (el, at) => {
                    at('rvXMLIvarName', 'groups');

                    groups.forEach(group => {
                        el('RVSlideGrouping', (el, at) => {
                            at('name', group.label);
                            at('color', this.getGroupColor(group.label));
                            at('uuid',  this.generateUniqueID());
                            
                            el('array', (el, at) => {
                                at('rvXMLIvarName', 'slides');
                                
                                // generate the slide for each group
                                group.slides.forEach(lines => this.makeSlide(lines, height, el));
                            });                                                       
                        });
                    });                                     
                });

                el('array', (el, at) => at('rvXMLIvarName', 'arrangements'));
            })
        }, {addDeclaration: true});
    }

    // Returns an XML string with white Helvetica text in RTF, Base64, format
    makeSlide = (lines, height, el) => {  
        // from 720p      
        el('RVDisplaySlide', (el, at) => {                
            at('backgroundColor', '0 0 0 1');
            at('highlightColor', '0 0 0 0');
            at('drawingBackgroundColor', false);
            at('enabled', true);
            at('hotKey', '');
            at('label', '');
            at('notes', '');
            at('uuid',  this.generateUniqueID());
            at('chordChartPath', '');

            el('array', (el, at) => at('rvXMLIvarName', 'cues'));
            el('array', ((el, at) => {
                at('rvXMLIvarName', 'displayElements'); 

                el('RVTextElement', (el, at) => {
                    at('displayName', 'TextElement');
                    at('uuid',  this.generateUniqueID())
                    at('typeID', '0');
                    at('displayDelay', '0');
                    at('locked', false);
                    at('persistent', '0');
                    at('fromTemplate', false);
                    at('opacity', 1);                                                
                    at('source', '');
                    at('bezelRadius', '0');
                    at('rotation', '0');
                    at('drawingFill', false);
                    at('drawingShadow', false);
                    at('drawingStroke', false);
                    at('fillColor', '0 0 0 0');
                    at('adjustsHeightToFit', false);
                    at('verticalAlignment', '0');
                    at('revealType', '0');

                    el('RVRect3D', (el, at, val) => {
                        at('rvXMLIvarName', 'position');                        
                        val(height === 720 ? '{38 28 0 1205 664}' : '{56 42 0 1808 996}');
                    });
                    el('shadow', (el, at, val) => {
                        at('rvXMLIvarName', 'shadow');                        
                        val('0.000000|0 0 0 0.3333333432674408|{4.0000002002606152, -3.9999995259110506}');
                    });
                    el('dictionary', (el, at) => {
                        at('rvXMLIvarName', 'stroke');

                        el('NSColor', (el, at, val) => {
                            at('rvXMLDictionaryKey', 'RVShapeElementStrokeColorKey');
                            val('0 0 0 1');
                        });
                        el('NSNumber', (el, at, val) => {
                            at('rvXMLDictionaryKey', 'RVShapeElementStrokeWidthKey');
                            at('hint', 'double');
                            val('0');
                        });
                    });                                        
                    el('NSString', (el, at, val) => {
                        at('rvXMLIvarName', 'RTFData');
                        val(this.encodeLinesToRTF(lines));
                    });                    
                });
            }));
        });                        
    }    
    
    encodeLinesToRTF = (lines) => {                 
        let firstLine = '{\\rtf1\\prortf1\\ansi\\ansicpg1252\\uc1\\htmautsp\\deff2{\\fonttbl{\\f0\\fcharset0 Times New Roman;}{\\f2\\fcharset0 Arial;}{\\f3\\fcharset0 Helvetica;}}{\\colortbl;\\red0\\green0\\blue0;\\red255\\green255\\blue255;}\\loch\\hich\\dbch\\pard\\slleading960\\plain\\ltrpar\\itap0{\\lang1033\\fs113\\outl0\\strokewidth-40\\strokec1\\f2\\cf2 \\cf2\\qc{\\fs107\\outl0\\strokewidth-40\\strokec1\\f3 {\\ltrch %%text%%}\\li0\\sa0\\sb0\\fi0\\qc\\sl1634\\slmult0\\par}\r\n';
        let slideLide = '{\\fs107\\outl0\\strokewidth-40\\strokec1\\f3 {\\ltrch %%text%%}\\li0\\sa0\\sb0\\fi0\\qc\\sl1634\\slmult0\\par}\r\n';        
                        
        let str = lines.reduce((curr, line, i) => {            
            curr += i === 0 ? firstLine : slideLide;            
            return curr.replace('%%text%%', line.trim());
        }, '') + '}}';

        return this.base64encode(str);
    }

    generateUniqueID = () => {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).toUpperCase().substring(1);            
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    base64encode = (str) => {
        return window.btoa(window.unescape(encodeURIComponent(str)));
    }

    getGroupColor = (groupName) => {
        let colorMap = {            
            'Intro': '1 1 0 1',
            'Verse 1': '0 0 1 1',
            'Verse 2': '0 1 0.498039215686275 1',
            'Verse 3': '1 0.647058823529412 0 1',
            'Verse 4': '0 1 0.501960814 1',
            'Verse 5': '0 1 0 1',
            'Verse 6': '0.501960814 1 0 1',
            'Pre-Chorus': '1 0.400000006 0.400000006 1',            
            'Chorus': '0.674509803921569 0.133333333333333 0.294117647058824 1',
            'Chorus 1': '1 0 0 1',
            'Chorus 2': '0.933333333333333 0.509803921568627 0.933333333333333 1',
            'Chorus 3': '0.662745098039216 0.662745098039216 0.662745098039216 1',            
            'Bridge': '0.501960784313725 0 0.501960784313725 1',            
            'Bridge 1': '1 1 1 1',
            'Bridge 2': '0.6 0.4 0.2 1',
            'Tag': '0 0 0 1',
            'End': '0 0 0 1'
        };
        
        return colorMap[groupName] || '1 1 1 0';
    }

}

export default ProPresenter;